import React from "react";
import { GlobalProvider } from "./src/context/GlobalContext";
import Layout from "./src/components/Layout";
import { IntlProvider } from "react-intl";
import messages_en from "./src/intl/en.json";
import messages_nl from "./src/intl/nl.json";
import messages_de from "./src/intl/de.json";
import messages_es from "./src/intl/es.json";
import messages_fr from "./src/intl/fr.json";
import messages_pl from "./src/intl/pl.json";
import * as queryString from "query-string";
import { Cookies } from "react-cookie-consent";

const messages = {
  en: messages_en,
  nl: messages_nl,
  de: messages_de,
  es: messages_es,
  fr: messages_fr,
  pl: messages_pl,
};

export const wrapPageElement = ({ element, props }) => {
  const language = props.pageContext.langKey;

  const urlParameters = queryString.parse(props.location.search);
  if (urlParameters["ref"]) {
    Cookies.set("ref", urlParameters["ref"], { expires: 7 });
  }

  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return (
    <IntlProvider locale={language} messages={messages[language]}>
      <Layout {...props}>{element}</Layout>
    </IntlProvider>
  );
};

export const wrapRootElement = ({ element }) => (
  <GlobalProvider>{element}</GlobalProvider>
);
