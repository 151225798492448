import React, { useLayoutEffect, useContext, useRef } from "react";
import { Link } from "gatsby";
import { ThemeProvider } from "styled-components";
import AOS from "aos";
import styled from "styled-components";
import { FormattedMessage, useIntl } from "react-intl";
import { getCurrentLangKey, getLangs, getUrlForLang } from "ptz-i18n";

import Header from "../Header";
import Footer from "../Footer";

import ModalVideo from "../ModalVideo";
// import ChatWidget from "../ChatWidget";
import useSiteMetadata from "../SiteMetadata";
import GlobalContext from "../../context/GlobalContext";
import GlobalStyle from "../../utils/globalStyle";
import "../../assets/fonts/fontawesome-5/webfonts/fa-brands-400.ttf";
import "../../assets/fonts/fontawesome-5/webfonts/fa-regular-400.ttf";
import "../../assets/fonts/fontawesome-5/webfonts/fa-solid-900.ttf";

// Standard typeface
import "../../assets/fonts/typography-font/Circular-Std-Book.ttf";
import "../../assets/fonts/typography-font/CircularStd-Black.ttf";
import "../../assets/fonts/typography-font/CircularStd-BlackItalic.ttf";
import "../../assets/fonts/typography-font/CircularStd-Bold.ttf";
import "../../assets/fonts/typography-font/CircularStd-BoldItalic.ttf";
import "../../assets/fonts/typography-font/CircularStd-Book.ttf";
import "../../assets/fonts/typography-font/CircularStd-BookItalic.ttf";
import "../../assets/fonts/typography-font/CircularStd-Medium.ttf";
import "../../assets/fonts/typography-font/CircularStd-MediumItalic.ttf";

// Custom typeface for Polish language
// import "../../assets/fonts/montserrat/Montserrat-Bold.ttf";
// import "../../assets/fonts/montserrat/Montserrat-BoldItalic.ttf";
// import "../../assets/fonts/montserrat/Montserrat-ExtraBold.ttf";
// import "../../assets/fonts/montserrat/Montserrat-ExtraBoldItalic.ttf";
// import "../../assets/fonts/montserrat/Montserrat-Medium.ttf";
// import "../../assets/fonts/montserrat/Montserrat-MediumItalic.ttf";
// import "../../assets/fonts/montserrat/Montserrat-SemiBold.ttf";
// import "../../assets/fonts/montserrat/Montserrat-SemiBoldItalic.ttf";

import "../../assets/fonts/icon-font/fonts/avasta.ttf";
import "../../assets/fonts/icon-font/css/style.css";

import "./bootstrap-custom.scss";
import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";
import "../../../node_modules/aos/dist/aos.css";

import "../../assets/fonts/icon-font/css/style.css";
import "../../assets/fonts/typography-font/typo.css";
// import "../../assets/fonts/montserrat/typo.css";
import "../../assets/fonts/fontawesome-5/css/all.css";

import CookieConsent, { Cookies } from "react-cookie-consent";
// import { useLocation } from "@reach/router";
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies";
// import ReactGA from "react-ga";

import { get, merge } from "lodash";

// the full theme object
import { theme as baseTheme } from "../../utils";
import SEO from "../SEO";
import { langKeyDefault, siteUrl } from "../../../buildVariables";

// options for different color modes
const modes = { light: "light", dark: "dark" };

// merge the color mode with the base theme
// to create a new theme object
const getTheme = (mode) =>
  merge({}, baseTheme, {
    colors: get(baseTheme.colors.modes, mode, baseTheme.colors),
  });

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.lightShade} !important;
`;

const Layout = ({ children, location, pageContext }) => {
  const gContext = useContext(GlobalContext);
  const { menu } = useSiteMetadata();
  const url = location.pathname;
  const langs = ["en", "nl", "de", "es", "fr", "pl"];
  const langNames = [
    "English",
    "Nederlands",
    "Deutsch",
    "Español",
    "Français",
    "Polski",
  ];
  const defaultLangKey = langKeyDefault;
  const langKey = getCurrentLangKey(langs, defaultLangKey, url);
  const homeLink = `/${langKey}/`.replace(`/${defaultLangKey}/`, "/");
  const langsMenu = getLangs(langs, langKey, getUrlForLang(homeLink, url)).map(
    (item, i) => ({
      ...item,
      number: i,
      link: item.link.replace(`/${defaultLangKey}/`, "/"),
    })
  );

  useLayoutEffect(() => {
    AOS.init();
  }, []);

  // Navbar style based on scroll
  const eleRef = useRef();

  const intl = useIntl();
  const affiliate = Cookies.get("ref");

  return (
    <>
      <ThemeProvider theme={getTheme(modes.light)}>
        <GlobalStyle />

        <SEO pageContext={pageContext} location={location} />

        <CookieConsent
          enableDeclineButton
          location="bottom"
          containerClasses="cookie-consent"
          buttonWrapperClasses="cookie-consent-buttons"
          buttonClasses="cookie-consent-button"
          declineButtonClasses="cookie-consent-button"
          contentClasses="cookie-consent-text"
          buttonText={intl.formatMessage({
            id: "cookies.accept",
            defaultMessage: "Accept",
          })}
          declineButtonText={intl.formatMessage({
            id: "cookies.decline",
            defaultMessage: "Decline",
          })}
          buttonStyle={{
            borderRadius: 5,
            backgroundColor: "#1578de",
            color: "#fff",
          }}
          declineButtonStyle={{
            background: "none",
            color: "#fff",
            borderRadius: 5,
            fontSize: 14,
            margin: "15px 0px 15px 15px",
            border: "none",
          }}
          onAccept={() => {
            Cookies.set("gatsby-gdpr-google-analytics", true);
            Cookies.set("gatsby-gdpr-google-tagmanager", true);
            Cookies.set("gatsby-gdpr-facebook-pixel", true);
            initializeAndTrack(location);
            // ReactGA.initialize("UA-104350488-1");
          }}
          onDecline={() => {
            Cookies.set("gatsby-gdpr-google-analytics", false);
            Cookies.set("gatsby-gdpr-google-tagmanager", false);
            Cookies.set("gatsby-gdpr-facebook-pixel", false);
          }}
          // debug={true}
        >
          <FormattedMessage id="cookies.hey" defaultMessage="Hey" />{" "}
          <span role="img" aria-label="welcome" style={{ paddingRight: 10 }}>
            👋
          </span>
          <FormattedMessage
            id="cookies.info"
            defaultMessage="We use cookies to give you the best experience on our website, in accordance with our"
          />{" "}
          <StyledLink
            to={
              "/" +
              (langKey === defaultLangKey ? "" : langKey + "/") +
              "privacy"
            }
            color="lightShade"
          >
            <FormattedMessage
              id="cookies.privacypolicy"
              defaultMessage="privacy policy"
            />
          </StyledLink>
          {langKey === "de" && " beschrieben"}.
        </CookieConsent>

        <div className="site-wrapper overflow-hidden" ref={eleRef}>
          <Header
            isDark={gContext.headerDark}
            changeOnScroll={gContext.changeOnScroll}
            homeLink={homeLink}
            langs={langsMenu}
            langNames={langNames}
            siteUrl={siteUrl}
            affiliate={affiliate}
          />
          <div>{children}</div>
          <Footer
            isDark={gContext.footerDark}
            // langs={langsMenu}
            // langNames={langNames}
            menu={menu}
            langKey={langKey}
            homeLink={homeLink}
            defaultLangKey={defaultLangKey}
          />
        </div>
        {/* <ChatWidget /> */}
        <ModalVideo
          url={intl.formatMessage({
            id: "videoPromoURL",
            defaultMessage: "https://www.youtube.com/watch?v=JlapTNtWpx8",
          })}
        />
      </ThemeProvider>
    </>
  );
};

export default Layout;
