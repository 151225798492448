import React from "react";
import { Link } from "gatsby";
import imgL1Logo from "../../assets/image/logo-color.png";
import imgL1LogoWhite from "../../assets/image/logo-white.png";

const Logo = ({ white, homeLink, changeOnScroll = false, height, className = "", ...rest }) => {
  return (
    <Link to={homeLink} className={`${className}`} {...rest}>
      {white ? (
        <img src={imgL1LogoWhite} alt="logo" style={{ width: 120 }} />
      ) : (
        changeOnScroll ? (
        <img src={imgL1LogoWhite} alt="logo" style={{ width: 120 }} />
        ) : (
          <img src={imgL1Logo} alt="logo" style={{ width: 120 }} />
        )
      )}
    </Link>
  );
};

export default Logo;
